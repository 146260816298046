import cn from 'classnames'
import styles from '../vacancy.module.scss'
import Button from '../../../../UI/Button/Button'
import React from 'react'
import { deleteVacancyRequest } from '../../../../api/deleteApi'
import { deleteAppliedJobs, setJobsCount } from '../../../../redux/actions/vacancies.action'
import { notify } from '../../../../helpers/notify'
import { useDispatch } from 'react-redux'
import VacancyIconsInfo from './VacancyIconsInfo'
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow-down.svg'
import { ReactComponent as TickImg } from '../../../../assets/icons/tick.svg'
import { trackVacancyCancel } from '../../../../utils/gtm'

const VacancyFooter = ({ vacancy, appliedJobs, isAdmin, loading, setLoading, count, isOpen }) => {
  const dispatch = useDispatch()

  const cancelVacancy = async (id, e) => {
    e.stopPropagation()
    setLoading(true)

    try {
      await deleteVacancyRequest(id)
      trackVacancyCancel(id)
      dispatch(deleteAppliedJobs(id))
      dispatch(setJobsCount(count - 1))
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setLoading(false)
    }
  }
  return (
    <>
      <div className={styles.vacancyShortInfo}>
        <div className={styles.header}>
          <VacancyIconsInfo type={'sea'} vacancy={vacancy} />
        </div>
        <div
          className={cn(styles.footer, {
            [styles.footer]: true,
          })}
        >
          {vacancy.request_sent_date && (
            <>
              <p className={cn(styles.requestSentDate, { [styles.requestSentDate_appliedJobs]: appliedJobs })}>
                <TickImg /> {vacancy.request_sent_date}
              </p>

              {appliedJobs && (
                <Button
                  className={styles.deleteApplication}
                  size='middle-full'
                  onClick={(e) => cancelVacancy(vacancy.id, e)}
                  disabled={isAdmin || loading}
                >
                  Delete
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.toOpenBlock}>
        <ArrowDownIcon
          className={cn(styles.ArrowIcon, {
            [styles.flippedArrowIcon]: isOpen,
          })}
        />
      </div>
    </>
  )
}
export default VacancyFooter
