import React, { useEffect } from 'react'
import styles from './vesselNameHistory.module.scss'
import { getDateByFormat } from '../../../../helpers/timeHelper'
import Spinner from '../../../../UI/Spinner/Spinner'
import moment from 'moment'
import UseSmartTooltip from '../../../../hooks/useSmartTooltip'

const VesselNameHistory = ({ id, getRenameHistory, vessels, onScroll, loading }) => {
  const getLabel = (item) => {
    const { name, date_from, date_till } = item
    const dateFrom = getDateByFormat(moment(date_from).toISOString(), 'YYYY/MM')
    const dateTo = getDateByFormat(moment(date_till).toISOString(), 'YYYY/MM')

    return `${name} (${dateFrom}-${dateTo})`
  }
  useEffect(() => {
    getRenameHistory(id)
    //eslint-disable-next-line
  }, [id])

  return (
    <div className={styles.wrapper}>
      <p className={styles.label}>This vessel also had other names:</p>
      <span>(Information entered by other users)</span>

      <div className={styles.body} onScroll={onScroll}>
        <span>{!vessels.length && '—'}</span>

        {vessels.map((item, index) => (
          <UseSmartTooltip tagType={'p'} amount_of_line={1} className={styles.label_description} key={index}>
            {getLabel(item)}
          </UseSmartTooltip>
        ))}

        {loading && <Spinner className={styles.spinner} />}
      </div>
    </div>
  )
}

export default VesselNameHistory
