import { setToken } from './user.action'
import { notify } from '../../helpers/notify'
import { request } from '../../api/auth.service'
import { API_BASE_V2, CLIENT_ID, CLIENT_SECRET, METHOD } from '../../api/constans'
import { trackSignIn } from '../../utils/gtm'

export const getUserToken = (data, setIsButtonDisabled) => {
  return (dispatch) => {
    return dispatch(userPostFetch(`token/`, { ...data, grant_type: 'password' }, setIsButtonDisabled))
  }
}

export const getUserSocialToken = (data) => {
  return (dispatch) => {
    return dispatch(
      userPostFetch(`convert-code/`, {
        ...data,
        grant_type: 'convert_token',
      })
    )
  }
}

export const getUserAppleToken = (data) => {
  let url = 'auth/convert-token/'

  return (dispatch) => {
    return dispatch(
      userPostFetch(url, {
        ...data,
        grant_type: 'convert_token',
      })
    )
  }
}

const userPostFetch = (url, data, setIsButtonDisabled) => {
  if (setIsButtonDisabled) setIsButtonDisabled(true)

  return async (dispatch) => {
    try {
      const api = url === 'convert-code/' || url === 'auth/convert-token/' ? null : API_BASE_V2

      const response = await request(url, {
        api,
        method: METHOD.POST,
        withoutToken: true,
        body: JSON.stringify({ ...data, client_id: CLIENT_ID, client_secret: CLIENT_SECRET, scope: 'seafarer' }),
      })

      if (setIsButtonDisabled) setIsButtonDisabled(false)

      dispatch(setToken(response))
      trackSignIn('email')
    } catch (error) {
      if (setIsButtonDisabled) setIsButtonDisabled(false)

      notify.errorsList(error.errors)

      dispatch(throwError(error))
    }
  }
}

export const throwError = (obj) => ({
  type: 'LOGIN_FAIL',
  payload: obj,
})

export const userLogout = () => {
  localStorage.removeItem('user')
  localStorage.removeItem('settings')

  return {
    type: 'USER_LOGOUT',
  }
}
