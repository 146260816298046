import React, { useState } from 'react'
import styles from './CvForm.module.scss'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Input from '../../../UI/Input/Input'
import Button from '../../../UI/Button/Button'
import { postAnonymousCV, postDocumentsFile } from '../../../api/postApi'
import { trackCvSend } from '../../../utils/gtm'

import { useSelector } from 'react-redux'
import { showAlert } from '../../AlertTemplate/AlertTemplate'
import Spinner from '../../../UI/Spinner/Spinner'
import { useNotify } from '../../../hooks/useNotify'
import { ReactComponent as Close } from '../../../assets/icons/close-accent-color.svg'
import { ReactComponent as Attach } from '../../../assets/icons/attach.svg'
import cn from 'classnames'
import PropTypes from 'prop-types'
import useClickOutside from '../../../hooks/useClickOutside'
import FileUploader from '../../FileUploader/FileUploader'
import { isValidFile } from '../../../helpers/documents.helper'
import { confirm } from '../../../UI/Confirm/Confirm'
import { isEmpty } from 'lodash'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Field is required'),
})

const CvForm = ({ setIsOpen }) => {
  const { notify } = useNotify()
  const popup = useClickOutside(() => setIsOpen(false))
  const { email, id } = useSelector(({ user }) => user.profile)
  const isAuth = useSelector(({ authentication }) => authentication.isAuth)
  const [filesCv, setFilesCv] = useState([])
  const [loading, setLoading] = React.useState(false)
  const extensions = ['.pdf', '.jpg', '.jpeg', '.png']

  const formik = useFormik({
    initialValues: {
      email: email || '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlue: true,
    validateOnMount: true,
    onSubmit: (values) => {
      if (filesCv) {
        setLoading(true)
        if (email && id && isAuth) {
          postDocumentsFile(filesCv[0], null, 'processing_cv', true)
            .then((data) => {
              setIsOpen(false)
              formik.resetForm()
              setLoading(false)
              showAlert('Your CV was submitted successfully.', alert, data)
              trackCvSend(values.email, filesCv[0].name)
            })
            .catch(({ errors }) => {
              setIsOpen(false)
              formik.resetForm()
              setLoading(false)
              notify.errorsList(errors)
            })
        } else {
          postAnonymousCV('processing_cv', filesCv[0], values.email, true)
            .then((data) => {
              setIsOpen(false)
              formik.resetForm()
              setLoading(false)
              showAlert('Your CV was submitted successfully.', alert, data)
              trackCvSend(values.email, filesCv[0].name)
            })
            .catch(({ errors }) => {
              setIsOpen(false)
              formik.resetForm()
              setLoading(false)
              notify.errorsList(errors)
            })
        }
      } else {
        notify.error('Error', 'CV file is required.', {})
      }
    },
  })
  const { errors, touched, values } = formik

  const handleInputFileChange = ({ target }) => {
    const files = target.files
    for (let i = 0; i < target.files.length; i++) {
      const file = files.item(i)
      if (file.size / 1048576 > 30) {
        notify.error('The maximum file size that can be uploaded is 30mb!')
        return
      }
      if (filesCv.length < 1) {
        if (isValidFile(file, extensions) !== -1) {
          setFilesCv((prevState) => [...prevState, file])
        } else {
          notify.error('Error', `You can only add formats: ${extensions.join(' ')}`)
        }
      } else {
        notify.error('Error', `You can only add up to 1 files`)
      }
    }
  }

  const handleInputFileDelete = (id, name, deleteType) => {
    confirm('Are you sure?', 'You want to delete this file?', () => {
      setFilesCv((prevState) => prevState.filter((file, index) => index !== id))
    })
  }

  return (
    <div className={styles.widget}>
      <form ref={popup} className={styles.cv_form} onSubmit={formik.handleSubmit}>
        <h2 className={styles.alarm_text}>Limited Offer!</h2>
        <div className={styles.cv_description}>
          {email ? (
            <p>
              Send your CV form in any format and get your registration. <br /> Your information will be processed to
              fill out your online account
            </p>
          ) : (
            <p>
              Send your CV form in any format and get registered. <br />
              Your information will be processed to fill out your <br /> online account
            </p>
          )}
        </div>
        <div className={cn('form-group', styles.form_data)}>
          <Input
            name='email'
            value={values.email}
            onChange={formik.handleChange}
            label='Your Email'
            isInvalid={errors.email && touched.email}
            disabled={email && isAuth}
            className={styles.email_cv}
          />
          <FileUploader
            text='Add your CV'
            onChange={handleInputFileChange}
            onDelete={handleInputFileDelete}
            files={filesCv}
            name='Add your CV'
            theme={'cv'}
            icon={<Attach />}
          />
          <Button type='submit' size='small' className={styles.submit} disabled={isEmpty(filesCv) || loading}>
            {loading ? <Spinner size={26} /> : 'Send'}
          </Button>
        </div>
        <Button
          type='button'
          size='small'
          theme='bordered'
          className={styles.closeForm}
          onClick={() => setIsOpen(false)}
        >
          <Close />
        </Button>
      </form>
    </div>
  )
}

CvForm.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
}

export default CvForm
