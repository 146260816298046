import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import styles from './styles.module.scss'
import Button from '../../UI/Button/Button'
import videoSvg from '../../assets/icons/video-camera.svg'
import ButtonFileUploader from '../FileUploader/ButtonFileUploader'
import { events } from '../../constants/constans'
import { getVideoResume, getVideoResumeStatus } from '../../api/getApi'
import { createFormDataForFiles } from '../../api/postApi'
import { deleteFilesByType } from '../../api/deleteApi'

import socket from '../../websocket'

import { useNotify } from '../../hooks/useNotify'
import cn from 'classnames'
import { API_BASE } from '../../api/constans'
import { getUserTimeZone } from '../../helpers/timeHelper'
import { confirm } from '../../UI/Confirm/Confirm'
import useListenEvent from '../../hooks/useListenEvent'

const VideoResumeUploader = () => {
  const { notify } = useNotify()
  const [videoUrl, setVideoUrl] = useState(null)
  const [loading, setLoading] = useState(false)
  const [isOnConvert, setIsOnConvert] = useState(false)
  const [progress, setProgress] = useState(0)
  const [cancelToken, setCancelToken] = useState(null)
  const [jobId, setJobId] = useState(null)
  const [isButtonCancelDisabled, setIsButtonCancelDisabled] = useState(false)
  const { video_resume } = events

  const videoRef = useRef(null)

  const handleUpload = ({ target }) => {
    setJobId(null)
    const token = JSON.parse(localStorage.getItem('user'))
    const headers = {
      Authorization: `Bearer ${token.access_token}`,
      'X-User-Time-Zone': getUserTimeZone(),
      'X-User-Agent': 'web',
    }
    const cancel = axios.CancelToken.source()
    setCancelToken(cancel)

    const size = target.files[0].size > 250000000
    if (token && !size) {
      setProgress(0)
      setLoading(true)
      const url = `${API_BASE}seafarer/upload-transcoder/`
      const config = {
        headers,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 70) / progressEvent.total)
          setProgress(percentCompleted)
        },
        cancelToken: cancel.token,
      }

      const formDate = createFormDataForFiles(target.files[0])
      axios
        .post(url, formDate, config)
        .then((response) => {
          setJobId(response.data.video_task)
          socket.emit('get.video.resume.status', { job_id: response.data.video_task })
        })
        .catch((err) => {
          setLoading(false)
          if (err.response) {
            err.response.data.errors.forEach(({ message }) => notify.error('Error', message[0]))
          } else {
            notify.error(err.message)
          }
        })
    } else {
      notify.error('Please do not use a video file larger than 250 MB')
    }
  }

  const handleCancelUpload = () => {
    setIsButtonCancelDisabled(true)
    if (!jobId && !!cancelToken) {
      confirm('Are you sure?', 'Do you want to cancel this upload?', () => {
        cancelToken.cancel('Upload canceled by user')
        setIsButtonCancelDisabled(false)
      })
    } else if (jobId) {
      confirm('Are you sure?', 'Do you want to cancel this upload?', () => {
        socket.emit('cancel.video.resume', { job_id: jobId })
      })
      setIsButtonCancelDisabled(false)
    }
  }

  const handleDelete = () => {
    confirm('Are you sure?', 'Do you want to delete this video?', () => {
      deleteFilesByType('video').then((data) => {
        if (data.ok) {
          setVideoUrl(null)
          notify('Success', 'video was successfully deleted.')
        } else {
          notify.errorsList(data.errors)
        }
      })
    })
  }

  const handleVideoResumeReady = (data) => {
    setIsOnConvert(false)
    setVideoUrl(data)
    setProgress(0)
    setLoading(false)
    setCancelToken(null)
    setJobId(null)
    notify('Success', 'Video resume was successfully converted.')
  }

  const handleVideoResumeStatus = (data) => {
    setIsButtonCancelDisabled(false)
    if (data.video_status === 'Canceled') {
      setIsOnConvert(false)
      setProgress(0)
      setLoading(false)
      notify('Success', 'Video resume was successfully deleted.')
    }
    if (data.video_status === 'Progressing') {
      setProgress(70)
      setLoading(true)
      setIsOnConvert(true)
      if (data.percent_complete && progress < data.percent_complete) {
        setProgress(data.percent_complete)
      }
    }
  }

  useListenEvent(video_resume.ready, handleVideoResumeReady)
  useListenEvent(video_resume.status, handleVideoResumeStatus)

  useEffect(() => {
    getVideoResumeStatus().then(({ video_converted, video_exist, video_task }) => {
      if (!video_converted && video_exist) {
        setIsOnConvert(true)
        setProgress(70)
        setJobId(video_task)
      }
    })
    getVideoResume().then((data) => {
      if (data.count !== 0) {
        setVideoUrl(data.results)
      }
    })
  }, [])

  return (
    <div className={styles.block}>
      <div className={cn(styles.block_video, { [styles.uploading]: loading || isOnConvert })}>
        {videoUrl && !isOnConvert && !loading ? (
          <video
            src={videoUrl[0].file}
            key={videoUrl[0].id}
            ref={videoRef}
            controls
            className={cn(styles.video, { [styles.onConvert]: isOnConvert || loading })}
          >
            {videoUrl.map(({ id, file, file_name }) => (
              <source key={id} src={file} type={`video/${file_name.split('.')[1]}`} />
            ))}
            Your browser does not support the video tag.
          </video>
        ) : (
          <div className={cn(styles.emptyVideo, { [styles.onConvert]: isOnConvert || loading })}>
            <div className={styles.icon}>
              <img src={videoSvg} alt='video camera' />
            </div>
          </div>
        )}
        {(loading || isOnConvert) && (
          <>
            <progress value={progress} max={100} className={styles.progressUpload} />
            <div className={styles.block_upload}>
              <p className={styles.uploading_info}>uploading {progress}%</p>
              <Button
                theme='text'
                disabled={isButtonCancelDisabled}
                className={styles.abort_button}
                onClick={handleCancelUpload}
              ></Button>
            </div>
          </>
        )}
      </div>
      {!videoUrl && !loading && !isOnConvert && (
        <div className={styles.formats}>
          Please use formats: <b>mp4, avi, mov, mpg, mpeg</b>
          <br />
          Please do not use a video file larger than <b>250 MB</b>
        </div>
      )}

      <div className={cn(styles.controls, { [styles.controlsDelete]: videoUrl })}>
        {videoUrl && !loading && !isOnConvert && (
          <Button
            className={styles.deleteVideo}
            disabled={loading || isOnConvert}
            onClick={handleDelete}
            size='middle'
            theme='bordered'
          >
            Delete video
          </Button>
        )}
        {!loading && !isOnConvert && (
          <ButtonFileUploader
            className={styles.uploaderVideo}
            text={videoUrl ? 'Change video' : 'Add video'}
            accept='video/mov, .mov, video/avi, .avi, video/mp4, video/mpg, .mpg, video/mpeg, .mpeg'
            onChange={handleUpload}
            disabled={loading || isOnConvert}
            name='video-resume'
          />
        )}
      </div>
    </div>
  )
}

export default VideoResumeUploader
