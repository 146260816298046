import React, { useState } from 'react'

import styles from './styles.module.scss'
import cn from 'classnames'

import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { getSeafarerLicense, getAuthProfileFilters, getGooglePlacesCities } from '../../../../api/getApi'
import { postDocumentsFile, postSeafarerLicense } from '../../../../api/postApi'
import { putSeafarerLicense } from '../../../../api/putApi'
import { deleteSeafarerFile } from '../../../../api/deleteApi'
import { documentSubmitHelper, getDocumentHelper, isFile, isValidFile } from '../../../../helpers/documents.helper'
import { getMinDate, today } from '../../../../helpers/timeHelper'
import { notify } from '../../../../helpers/notify'
import {
  addAuthDocument,
  editAuthDocument,
  editDocument,
  setDocument,
} from '../../../../redux/actions/documents.action'
import { setUserInfo } from '../../../../redux/actions/user.action'
import { confirm } from '../../../../UI/Confirm/Confirm'
import Spinner from '../../../../UI/Spinner/Spinner'
import SelectSearch from '../../../../UI/Select/SelectSearch'
import Input from '../../../../UI/Input/Input'
import Autocomplete from '../../../../UI/Select/Autocomplete'
import KeyBoardDatePicker from '../../../../UI/KeyBoardDatePicker/KeyBoardDatePicker'
import CheckBox from '../../../../UI/CheckBox/CheckBox'
import Button from '../../../../UI/Button/Button'
import FileUploader from '../../../FileUploader/FileUploader'
import { ReactComponent as AttachIcon } from '../../../../assets/icons/add_file_icon.svg'
import { initialValues, getValidationSchema } from './config'
import { isCompletelyFilled } from '../../../../helpers/objectsHelper'

const extensions = ['.pdf', '.doc', '.docx', '.jpg', '.jpeg', '.png', '.xlsx', '.xls']

const LicenseForm = ({
  currentDocumentId,
  setCurrentDocumentId,
  documentFiles,
  setDocumentFiles,
  fillFields,
  setFillFields,
  authDocuments,
}) => {
  const dispatch = useDispatch()
  const dateFormat = useSelector(({ user }) => user.settings.date_format)

  const [isButtonEnable, setIsButtonEnable] = React.useState(false)
  const [isIdenticalNumber, setIsIdenticalNumber] = React.useState(false)
  const [endorsementPresent, setEndorsementPresent] = React.useState(false)
  const [fetchedFilesLength, setFetchedFilesLength] = React.useState(0)
  const [autocompleteItems, setAutocompleteItems] = React.useState([])
  const [documentData, setDocumentData] = React.useState()
  const [documentFilesForDeleting, setDocumentFilesForDeleting] = React.useState([])
  const [endorsementFilesForDeleting, setEndorsementFilesForDeleting] = React.useState([])
  const [filters, setFilters] = React.useState({
    countries: [],
    licenseTypes: [],
  })
  const [fileValue, setFileValue] = useState({
    license: '',
    endorsement: '',
  })
  const [isUpload, setIsUpload] = useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const formik = useFormik({
    initialValues: currentDocumentId && documentData ? documentData : initialValues,
    validationSchema: getValidationSchema(dateFormat, endorsementPresent),
    validateOnChange: true,
    enableReinitialize: true,

    onSubmit: (document) => {
      setIsLoading(true)

      if (currentDocumentId) {
        editLicense(documentSubmitHelper(document, 'license'))
      } else {
        postLicense(documentSubmitHelper(document, 'license'))
      }
    },
  })

  const { values, touched, errors, dirty } = formik

  const resetForm = () => {
    formik.resetForm()
    setIsIdenticalNumber(false)
    setDocumentFiles({
      license: [],
      endorsement: [],
    })
  }

  const changeFiles = async (documentId, action, type = 'post') => {
    setIsUpload(true)

    let promises = []

    if (documentFiles.license.length) {
      documentFiles.license.forEach((file) => {
        if (isFile(file)) {
          promises.push(postDocumentsFile(file, documentId, 'license'))
        }
      })
    }

    if (values.endorsement.country.id) {
      if (documentFiles.endorsement.length) {
        documentFiles.endorsement.forEach((file) => {
          if (isFile(file)) {
            promises.push(postDocumentsFile(file, documentId, 'endorsement'))
          }
        })
      }
    }
    if (values.endorsement.country.id) {
      documentFilesForDeleting.forEach((file) => {
        promises.push(deleteSeafarerFile(file))
      })
      endorsementFilesForDeleting.forEach((file) => {
        promises.push(deleteSeafarerFile(file))
      })
    } else {
      documentFilesForDeleting.forEach((file) => {
        promises.push(deleteSeafarerFile(file))
      })
    }

    try {
      const response = await Promise.all(promises)
      const passport = await getSeafarerLicense(documentId)

      if (type === 'post') dispatch(action(passport))

      if (type === 'put') dispatch(action(passport.id, passport))

      if (response.some(({ errors }) => errors)) {
        response.forEach(({ errors }) => errors && notify.errorsList(errors))
      }
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setIsUpload(false)
    }
  }

  const postLicense = async (document) => {
    try {
      const response = await postSeafarerLicense(document)

      if (response.errors) {
        notify.errorsList(response.errors)

        return
      }

      if (!!documentFiles.license.length || !!documentFiles.endorsement.length) {
        await changeFiles(response.id, addAuthDocument)
      } else {
        dispatch(setDocument(getSeafarerLicense, response.id))
      }

      if (!authDocuments.length) {
        dispatch(setUserInfo())
      }

      resetForm()

      // setIsIdenticalNumber(false)
      notify('Success', `Your document was successfully added`)
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setIsLoading(false)
    }
  }

  const editLicense = async (document) => {
    try {
      const response = await putSeafarerLicense(document, currentDocumentId)

      if (response.errors) {
        notify.errorsList(response.errors)

        return
      }

      if (documentFiles.license.length || documentFiles.endorsement.length || documentFilesForDeleting.length) {
        await changeFiles(currentDocumentId, editAuthDocument, 'put')
      }

      if (!documentFiles.license.length || !documentFiles.endorsement.length || !documentFilesForDeleting.length) {
        dispatch(editDocument(getSeafarerLicense, currentDocumentId))
      }

      resetForm()

      setCurrentDocumentId(null)
      setDocumentFilesForDeleting([])
      setEndorsementFilesForDeleting([])
      notify('Success', `Your document was successfully updated`)
    } catch (error) {
      notify.errorsList(error.errors)
    } finally {
      setIsLoading(false)
    }
  }

  const handleInputFileChange = ({ target }) => {
    const files = target.files

    if (target.id === 'licensecv_file') {
      setFileValue((prev) => ({
        ...prev,
        license: target.value,
      }))
    } else {
      setFileValue((prev) => ({
        ...prev,
        endorsement: target.value,
      }))
    }

    for (let i = 0; i < target.files.length; i++) {
      const file = files.item(i)
      if (file.size / 1048576 > 30) {
        notify.error('The maximum file size that can be uploaded is 30mb!')
        return
      }
      if (documentFiles[target.name].length < 5) {
        if (isValidFile(file, extensions) !== -1) {
          setDocumentFiles((prevState) => ({
            ...prevState,
            [target.name]: [...prevState[target.name], file],
          }))
        } else {
          notify.error('Error', `You can only add formats: ${extensions.join(' ')}`)
        }
      } else {
        notify.error('Error', `You can only add up to 5 files`)
      }
    }
  }

  const handleInputFileDelete = (id, name, deleteType) => {
    confirm('Are you sure?', 'You want to delete this file?', () => {
      if (name === 'license') {
        setFileValue((prev) => ({
          ...prev,
          license: '',
        }))
      } else {
        setFileValue((prev) => ({
          ...prev,
          endorsement: '',
        }))
      }

      if (currentDocumentId) {
        if (deleteType === 'byId') {
          if (name === 'license') {
            setDocumentFilesForDeleting((prevState) => [...prevState, id])
          }
          if (name === 'endorsement') {
            setEndorsementFilesForDeleting((prevState) => [...prevState, id])
          }
          setDocumentFiles((prevState) => ({
            ...prevState,
            [name]: prevState[name].filter((file) => file.id !== id),
          }))
        } else {
          setDocumentFiles((prevState) => ({
            ...prevState,
            [name]: prevState[name].filter((file, index) => index !== id),
          }))
        }
      } else {
        setDocumentFiles((prevState) => ({
          ...prevState,
          [name]: prevState[name].filter((file, index) => index !== id),
        }))
      }
    })
  }

  const handleIsIdenticalNumberCheckBox = () => {
    setIsIdenticalNumber(!isIdenticalNumber)
    if (isIdenticalNumber) {
      formik.setFieldValue('endorsement.number', '')
    } else if (!!values.number) {
      formik.setFieldValue('endorsement.number', values.number)
    }
  }

  const getPlaces = (name, target) => {
    setAutocompleteItems([])

    const document = name === 'city' ? formik.values : formik.values.endorsement

    if (target.value.length > 2) {
      getGooglePlacesCities(target.value, document.country.iso2.toLowerCase()).then((cities) => {
        setAutocompleteItems(cities)
      })
    }
  }

  const handleChangeValue = (value) => {
    if (!touched[value.target.name]) {
      formik.setFieldTouched(value.target.name, true)
    }

    formik.handleChange(value)
  }

  const handleChangeSelectValue = (name, value) => {
    if (name === 'country' && value.id !== values.country.id) {
      formik.setValues({
        ...values,
        city: { name: '', id: null },
      })
    } else if (name === 'endorsement.country' && value.id !== values.endorsement.country.id) {
      formik.setValues({
        ...values,
        endorsement: {
          ...values.endorsement,
          city: { name: '', id: null },
        },
      })
    }

    handleChangeValue({ target: { value, name } })
  }

  const handleChangeAutocompleteValue = (name, { target }) => {
    handleChangeValue({
      target: { value: { name: target.value, id: null }, name },
    })

    getPlaces(name, target)
  }

  const handleClickAutocomplete = (name, value) => {
    handleChangeValue({ target: { value, name } })

    setAutocompleteItems([])
  }

  const handleFocusAutoComplete = (name, { target }) => {
    getPlaces(name, target)
  }

  const isRequest = isLoading || isUpload

  const isDisabled = isLoading || !fillFields || isUpload

  React.useEffect(() => {
    const completelyFilledLicense = isCompletelyFilled(values, ['type', 'country', 'number', 'date_of_issue'])

    const identicalEndorsementNumber = isIdenticalNumber || !!values.endorsement.number

    const completelyFilledLicenseAndEndorsement =
      completelyFilledLicense &&
      !!values.endorsement.country.id &&
      !!values.endorsement.date_of_issue &&
      !!values.endorsement.date_of_expire &&
      !!identicalEndorsementNumber

    setEndorsementPresent(
      !!values.endorsement.country.id ||
        !!values.endorsement.date_of_issue ||
        !!values.endorsement.date_of_expire ||
        !!values.endorsement.number
    )
    const isNewDocumentFilesObjects =
      documentFiles.endorsement.every((obj) => 'id' in obj) && documentFiles.license.every((obj) => 'id' in obj)
    const isFilesLengthChanged = fetchedFilesLength !== documentFiles.license.length + documentFiles.endorsement.length
    setIsButtonEnable(
      endorsementPresent
        ? completelyFilledLicenseAndEndorsement && (dirty || !isNewDocumentFilesObjects || isFilesLengthChanged)
        : completelyFilledLicense && (dirty || !isNewDocumentFilesObjects || isFilesLengthChanged)
    )
    // eslint-disable-next-line
  }, [values, endorsementPresent, isIdenticalNumber, fileValue, documentFiles, dirty])

  React.useEffect(() => {
    getAuthProfileFilters('country', 'seafarer_licenses').then((filters) => {
      setFilters({
        countries: filters.country,
        licenseTypes: filters.seafarer_licenses,
      })
    })
  }, [])

  React.useEffect(() => {
    resetForm()

    if (currentDocumentId) {
      setIsLoading(true)

      getSeafarerLicense(currentDocumentId).then((document) => {
        setFillFields(true)

        setDocumentData(getDocumentHelper(document, 'license'))
        setFetchedFilesLength(document?.files?.length + document?.endorsement?.files?.length)

        if (document?.number === document?.endorsement?.number) {
          setIsIdenticalNumber(true)
        }

        if (document.files.length) {
          setDocumentFiles((prevState) => ({
            ...prevState,
            license: document.files.map((file) => {
              return {
                ...file,
                name: file.file_name,
              }
            }),
          }))
        }

        if (document?.endorsement?.files?.length) {
          setDocumentFiles((prevState) => ({
            ...prevState,
            endorsement: document.endorsement.files.map((file) => {
              return {
                ...file,
                name: file.file_name,
              }
            }),
          }))
        }
        setIsLoading(false)
      })
    } else {
      setEndorsementPresent(false)
      setFillFields(false)

      setDocumentFilesForDeleting([])
      setEndorsementFilesForDeleting([])
    }
    // eslint-disable-next-line
  }, [currentDocumentId])

  React.useEffect(() => {
    if (!!values.number && isIdenticalNumber) {
      formik.setFieldValue('endorsement.number', values.number)
    }
    //eslint-disable-next-line
  }, [values.number, isIdenticalNumber])

  return (
    <form
      className={cn(styles.form_license, {
        [styles.disabled]: isDisabled,
      })}
      onSubmit={formik.handleSubmit}
    >
      {isRequest && (
        <div className={styles.loadingContainer}>
          <Spinner size={50} borderColor='rgba(189, 189, 189, 0.47)' />
        </div>
      )}

      <h3 className={styles.title_column}>License</h3>
      <h3 className={styles.title_column}>Endorsement</h3>

      <div className={styles.license_name}>
        <SelectSearch
          label='Name'
          name='type'
          id={values.type?.id}
          items={filters.licenseTypes}
          selectedItem={values.type.name}
          onClick={handleChangeSelectValue}
          disabled={!fillFields}
          isInvalid={!!(errors.type?.id && touched.type)}
          required
        />

        {errors.type?.id && touched.type && <p className={styles.formItemError}>{errors.type?.id}</p>}
      </div>

      <div className={styles.form_license_column}>
        <div>
          <Input
            label='Number'
            name='number'
            value={values.number}
            onChange={handleChangeValue}
            disabled={!fillFields}
            isInvalid={!!(errors.number && touched.number)}
            required
          />

          {errors.number && touched.number && <p className={styles.formItemError}>{errors.number}</p>}
        </div>

        <div>
          <SelectSearch
            label='Country'
            name='country'
            id={values.country.id}
            items={filters.countries}
            selectedItem={values.country.name}
            onClick={handleChangeSelectValue}
            disabled={!fillFields}
            isInvalid={!!(errors.country?.id && touched.country)}
            required
          />

          {errors.country?.id && touched.country && <p className={styles.formItemError}>{errors.country?.id}</p>}
        </div>

        <div>
          <Autocomplete
            autoComplete={'new-password'}
            label='City'
            name='city'
            placeholder='City'
            items={autocompleteItems}
            value={values.city.name}
            onChange={handleChangeAutocompleteValue}
            onClick={handleClickAutocomplete}
            onFocus={handleFocusAutoComplete}
            disabled={!values.country.id || !fillFields}
            isInvalid={Boolean(errors.city?.id && touched.city)}
            errorMessage={errors.city?.id}
          />
        </div>

        <div className={styles.formRow}>
          <KeyBoardDatePicker
            formik={formik}
            value={values.date_of_issue}
            nameOfValue='date_of_issue'
            keyBoardLabel='Date of issue'
            minDate={getMinDate}
            maxDate={today}
            isInvalid={!!(errors.date_of_issue || (errors.date_of_issue && touched.date_of_issue))}
            errorMessage={errors.date_of_issue}
            disabled={!fillFields}
            today={true}
            required
          />

          <KeyBoardDatePicker
            formik={formik}
            value={values.date_of_expire}
            nameOfValue='date_of_expire'
            keyBoardLabel='Date of expire'
            minDate={values.date_of_issue || today}
            isInvalid={!!(errors.date_of_expire || (errors.date_of_expire && touched.date_of_expire))}
            errorMessage={errors.date_of_expire}
            disabled={!fillFields}
            today={true}
          />
        </div>

        <FileUploader
          name='license'
          text='Add scan'
          theme='attachFile'
          value={fileValue.license}
          files={documentFiles.license}
          onChange={handleInputFileChange}
          onDelete={handleInputFileDelete}
          icon={<AttachIcon />}
          disabled={!values.country.id}
          isUpload={isUpload}
        />
      </div>

      <div className={styles.form_endorsement_column}>
        <div
          className={cn(styles.formRow, styles.endorsement_number_row, {
            [styles.isIdenticalCheckBox]: isIdenticalNumber,
          })}
        >
          <CheckBox
            className={styles.checkbox_endorsement_number}
            onClick={handleIsIdenticalNumberCheckBox}
            checked={isIdenticalNumber}
          >
            Numbers are identical
          </CheckBox>
          <div className={styles.endorsement_number}>
            <Input
              label='Number'
              name='endorsement.number'
              value={values.endorsement.number}
              onChange={handleChangeValue}
              disabled={isIdenticalNumber}
              required={endorsementPresent}
            />
          </div>
        </div>

        <SelectSearch
          label='Country'
          name='endorsement.country'
          id={values.endorsement.country.id}
          items={filters.countries}
          selectedItem={values.endorsement.country.name}
          onClick={handleChangeSelectValue}
          disabled={!fillFields}
          isInvalid={!!(errors.endorsement?.country?.id && touched.endorsement?.country)}
          required={endorsementPresent || isIdenticalNumber || values.endorsement.number}
        />

        <div>
          <Autocomplete
            autoComplete={'new-password'}
            label='City'
            name='endorsement.city'
            placeholder='City'
            items={autocompleteItems}
            value={values.endorsement.city.name}
            onChange={handleChangeAutocompleteValue}
            onClick={handleClickAutocomplete}
            onFocus={handleFocusAutoComplete}
            disabled={!values.endorsement.country.id || !fillFields}
            isInvalid={Boolean(errors.endorsement?.city?.id && touched.endorsement?.city)}
            errorMessage={errors.endorsement?.city?.id}
          />
        </div>

        <div className={styles.formRow}>
          <KeyBoardDatePicker
            formik={formik}
            value={values.endorsement.date_of_issue}
            nameOfValue='endorsement.date_of_issue'
            keyBoardLabel='Date of issue'
            minDate={getMinDate}
            maxDate={today}
            isInvalid={
              !!(
                errors.endorsement?.date_of_issue ||
                (errors.endorsement?.date_of_issue && touched.endorsement?.date_of_issue)
              )
            }
            errorMessage={errors.endorsement?.date_of_issue}
            disabled={!fillFields}
            required={endorsementPresent || isIdenticalNumber || values.endorsement.number}
          />

          <KeyBoardDatePicker
            formik={formik}
            value={values.endorsement.date_of_expire}
            nameOfValue='endorsement.date_of_expire'
            keyBoardLabel='Date of expire'
            minDate={values.endorsement?.date_of_issue || today}
            isInvalid={
              !!(
                errors.endorsement?.date_of_expire ||
                (errors.endorsement?.date_of_expire && touched.endorsement?.date_of_expire)
              )
            }
            errorMessage={errors.endorsement?.date_of_expire}
            disabled={!fillFields}
            required={endorsementPresent || isIdenticalNumber || values.endorsement.number}
          />
        </div>

        <FileUploader
          name='endorsement'
          text='Add scan'
          value={fileValue.endorsement}
          files={documentFiles.endorsement}
          onChange={handleInputFileChange}
          onDelete={handleInputFileDelete}
          icon={<AttachIcon />}
          theme='attachFile'
          isUpload={isUpload}
          disabled={!values.endorsement.country.id}
        />
      </div>

      <Button className={styles.license_save} type='submit' size='middle-full' disabled={!isButtonEnable}>
        Save
      </Button>
    </form>
  )
}

export default LicenseForm
