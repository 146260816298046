import TagManager from 'react-gtm-module'

const sendGTMEvent = (eventName, eventData = {}) => {
  
  if (window.location.hostname === 'nextship.net') {
    TagManager.dataLayer({
      dataLayer: {
        event: eventName,
        ...eventData
      }
    })
    console.log('GTM Event:', eventName, eventData)
  }
}

// Auth Events
export const trackSignUp = (method) => {
  sendGTMEvent('sign_up', { method })
}

export const trackSignIn = (method) => {
  sendGTMEvent('login', { method })
}

export const trackSignOut = () => {
  sendGTMEvent('logout')
}

// Profile Events
export const trackProfileUpdate = (fieldType) => {
  sendGTMEvent('profile_update', { field_type: fieldType })
}

// Product Events
export const trackProductCheck = (productId, action) => {
  sendGTMEvent('product_check', { product_id: productId, action })
}

export const trackProductUnsubscribe = (productId, reason) => {
  sendGTMEvent('product_unsubscribe', { product_id: productId, reason })
}

export const trackProductSubscribe = (productId) => {
  sendGTMEvent('product_subscribe', { product_id: productId })
}

// CV Events
export const trackCvSend = (email, documentName) => {
  sendGTMEvent('cv_send', { email, document_name: documentName })
}

// Vacancy Events
export const trackVacancyApply = (vacancyId) => {
  sendGTMEvent('vacancy_apply', {
    vacancy_id: vacancyId,
  })
}

export const trackVacancyCancel = (vacancyId) => {
  sendGTMEvent('vacancy_cancel', {
    vacancy_id: vacancyId,
  })
}

export const trackVacancyShare = (vacancyId) => {
  sendGTMEvent('vacancy_share', {
    vacancy_id: vacancyId,
  })
}

// Navigation Events
export const trackPageView = (pagePath, pageTitle) => {
  sendGTMEvent('page_view', {
    page_path: pagePath,
    page_title: pageTitle
  })
}

// Payment Events
export const trackPaymentView = (paymentId, amount) => {
  sendGTMEvent('payment_view', {
    payment_id: paymentId,
    amount: amount
  })
}

export const trackPaymentComplete = (paymentId, amount) => {
  sendGTMEvent('payment_complete', {
    payment_id: paymentId,
    amount: amount
  })
}

// Content Interaction Events
export const trackContentView = (contentId, contentType) => {
  sendGTMEvent('content_view', {
    content_id: contentId,
    content_type: contentType
  })
}

export const trackAdInteraction = (adId, action) => {
  sendGTMEvent('ad_interaction', {
    ad_id: adId,
    action: action // 'click', 'view', etc.
  })
}
