import React from "react";
import SocialButton from "../../components/SocialButton/SocialButton";
import { ReactComponent as FacebookLogo } from "../../assets/icons/Facebook-auth-logo.svg";
import { trackSignIn } from "../../utils/gtm";

const FacebookAuthButton = () => {
  const setDomain = () => {
    if (
      window.location.href.includes("dev") ||
      window.location.href.includes("localhost")
    ) {
      return `dev.nextship.net`;
    } else if (window.location.href.includes("stage")) {
      return `stage.nextship.net`;
    } else {
      return `nextship.net`;
    }
  };

  const href = `https://www.facebook.com/v7.0/dialog/oauth?client_id=611245183117391&scope=email&redirect_uri=https://${setDomain()}/seafarer/signin/facebook`;

  const styles = {
    cursor: "auto",
    display: "block",
  };

  return (
    <a style={styles} href={href} onClick={() => trackSignIn('by_facebook')}>
      <SocialButton style={{ backgroundColor: "#314A7E" }}>
        <FacebookLogo />
      </SocialButton>
    </a>
  );
};

export default FacebookAuthButton;
