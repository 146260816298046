import styles from '../vacancy.module.scss'
import Button from '../../../../UI/Button/Button'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import Spinner from '../../../../UI/Spinner/Spinner'
import { ReactComponent as ShareIcon } from '../../../../assets/icons/share-icon.svg'
import { postVacancyRequest } from '../../../../api/postApi'
import { applyVacancyRecord } from '../../../../redux/actions/vacancies.action'
import { notify } from '../../../../helpers/notify'
import { trackVacancyApply, trackVacancyShare } from '../../../../utils/gtm'

const VacancyRightColumn = ({ vacancy, loading, setLoading, setIsShareBlockOpen, toOpenCard, isOpen }) => {
  const { isAdmin } = useSelector(({ authentication }) => authentication.user)
  const dispatch = useDispatch()

  const applyVacancy = async (company, vacancy, e) => {
    e.stopPropagation()
    setLoading(true)

    postVacancyRequest({ company, vacancy }).then((data) => {
      console.log(data);
      
      if (data.ok) {
        dispatch(applyVacancyRecord(vacancy, data.request_sent_date))
        trackVacancyApply(vacancy)
        setLoading(false)
      } else {
        notify.errorsList(data.errors)

        setLoading(false)
      }
    })
  }
  const toShareVacancy = (e) => {
    e.stopPropagation()
    console.log(vacancy)
    if (isOpen) {
      setIsShareBlockOpen(true)
      trackVacancyShare(vacancy.id)
    } else {
      toOpenCard(vacancy.id, isOpen)
      setIsShareBlockOpen(true)
    }
  }

  return (
    <div className={styles.rightColumn}>
      <div className={styles.controlsBlock}>
        {/*<div className={styles.shareBlockBtn}>*/}
        <Button
          className={cn(styles.controlBtn, {
            [styles.controlBtnDisabled]: isAdmin,
            [styles.shareBtn]: true,
            [styles.appliedVacancyShareBtn]: vacancy.request_sent_date,
          })}
          onClick={(e) => toShareVacancy(e)}
          disabled={isAdmin || loading}
          size={'small'}
          theme={'white'}
        >
          Share
          <ShareIcon className={styles.shareIcon} />
        </Button>
        {!vacancy.request_sent_date && (
          <Button
            className={cn(styles.controlBtn, {
              [styles.controlBtnDisabled]: isAdmin,
              [styles.controlBtnLoading]: loading,
            })}
            onClick={(e) => applyVacancy(vacancy.company_id, vacancy.id, e)}
            disabled={isAdmin || loading}
          >
            {loading ? (
              <div className={styles.loading}>
                <Spinner size={30} borderColor='rgba(189, 189, 189, 0.47)' />
              </div>
            ) : (
              'Apply'
            )}
          </Button>
        )}
      </div>
    </div>
  )
}
export default VacancyRightColumn
