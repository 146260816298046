import React, { useEffect, useRef, useState } from 'react'

import styles from './styles.module.scss'

import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'
import { userLogout } from '../../redux/actions/auth'
import { getAuthMe, getCv } from '../../api/getApi'
import { Link, useLocation } from 'react-router-dom'
import Spinner from '../../UI/Spinner/Spinner'
import Button from '../../UI/Button/Button'
import completed from '../../assets/icons/completed.svg'
import { profilePaths } from '../../constants/constans'
import { notify } from '../../helpers/notify'
import { trackSignOut } from '../../utils/gtm'

const UserProfileMore = ({ isAdmin }) => {
  const { picture, first_name: firstName, last_name: lastName, email, last_login } = useSelector(
    ({ user }) => user.profile
  )
  const { scope } = useSelector(({ authentication }) => authentication.user)

  const [lastLogin, setLastLogin] = useState(last_login)
  const [cvLoading, setCvLoading] = useState(false)

  const grand_total = useSelector(({ user }) => user.profile.filler.grand_total)

  const dispatch = useDispatch()

  const popup = useRef(null)

  const location = useLocation()

  const isSeafarer = scope === 'seafarer'
  const isProfile = location.pathname.includes('/profile')

  const logOut = () => {
    trackSignOut()
    dispatch(userLogout())
  }

  useEffect(() => {
    const getMe = async () => {
      try {
        const response = await getAuthMe(scope)

        setLastLogin(response.last_login)
      } catch (error) {
        notify.errorsList(error.errors)
      }
    }

    if (scope) getMe()

    return () => {
      setLastLogin('loading...')
    }
  }, [scope])

  const handleExportClick = () => {
    setCvLoading(true)

    const getCvSeafarer = async () => {
      try {
        const response = await getCv()

        setCvLoading(false)

        const file = window.URL.createObjectURL(response)
        const anchor = document.createElement('a')

        anchor.download = `${lastName}_CV.pdf`
        anchor.href = file
        anchor.click()
      } catch (error) {
        notify.errorsList(error.errors)
      }
    }

    getCvSeafarer()
  }

  return (
    <div ref={popup} className={styles.more}>
      <div className={styles.more_header}>
        {picture && <img src={picture.file_mini || picture.file} alt='user profile' />}

        <div className={styles.more_header_info}>
          <div className={styles.more_username}>
            {firstName} {lastName}
          </div>

          <div className={styles.more_email}> {email} </div>
        </div>
      </div>

      {grand_total >= 100 && (
        <div className={styles.more_header_complete_status}>
          <div className={styles.complete_info}>
            <span>Your profile completed on 100%</span>

            <img src={completed} alt='completed' />
          </div>

          <div className={styles.line_scale}></div>
        </div>
      )}

      <div className={styles.content}>
        <div className={styles.buttons}>
          <Link to={isProfile ? '/' : profilePaths.personal_data}>
            <Button disabled={isAdmin} size='middle-full'>
              {isProfile ? 'Main page' : 'Go to profile'}
            </Button>
          </Link>
        </div>

        <div className={styles.buttons}>
          <button onClick={logOut} className={styles.buttons_logout}>
            Sign out
          </button>

          {isSeafarer && (
            <button
              disabled={!firstName || !lastName || cvLoading}
              onClick={handleExportClick}
              className={styles.buttons_cv}
            >
              {cvLoading ? <Spinner size={26} /> : 'Export CV to PDF'}
            </button>
          )}
        </div>

        <div className={styles.more_footer}>Last session: {lastLogin}</div>
      </div>
    </div>
  )
}

UserProfileMore.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
}

export default UserProfileMore
