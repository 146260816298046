import React from 'react'
import Logo from '../../UI/Logo/Logo'
import styles from './Footer.module.scss'
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebook-footer.svg'
import { ReactComponent as LinkedInIcon } from '../../assets/icons/linkedIn-footer.svg'
import { ReactComponent as InstagramIcon } from '../../assets/icons/instagram-footer.svg'
import { ReactComponent as TikTok } from '../../assets/icons/tiktok-footer.svg'
import { ReactComponent as Telegram } from '../../assets/icons/telegram-footer.svg'
import { ReactComponent as YouTube } from '../../assets/icons/youtube.svg'
import cn from 'classnames'
import { getCurrentDomain } from '../../helpers/apiHelper'
import PrivacyTerms from '../PrivacyTerms/PrivacyTerms'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={cn(styles.wrapper, 'container')}>
        <Logo theme='white' className={styles.footer_logo} />
        <div className={styles.contact_us}>
          <p className={styles.title}>Contact US</p>
          <a className={styles.email} href='mailto:help@nextship.net'>
            help@nextship.net
          </a>
        </div>
        <div className={styles.privacy_terms}>
          <PrivacyTerms />
        </div>
        <div className={styles.social_links}>
          <a
            className={styles.social_link}
            href='https://www.facebook.com/nextship.page'
            target='_blank'
            rel='noopener noreferrer'
          >
            <FacebookIcon />
          </a>
          <a
            className={styles.social_link}
            href='https://www.linkedin.com/company/nextship'
            target='_blank'
            rel='noopener noreferrer'
          >
            <LinkedInIcon />
          </a>
          <a
            className={styles.social_link}
            href='https://www.instagram.com/next.ship/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <InstagramIcon />
          </a>
          <a
            className={styles.social_link}
            href='https://www.tiktok.com/@nextship.app'
            target='_blank'
            rel='noopener noreferrer'
          >
            <TikTok />
          </a>
          <a className={styles.social_link} href='https://t.me/nextshipapp' target='_blank' rel='noopener noreferrer'>
            <Telegram />
          </a>
          <a
            className={styles.social_link}
            href='https://www.youtube.com/channel/UCWSBAkCrcK5hy3y25rYfpKA'
            target='_blank'
            rel='noopener noreferrer'
          >
            <YouTube />
          </a>
        </div>

        <div className={styles.itemForShipowner}>
          <div className={styles.link}>
            <a href={`${getCurrentDomain()}employers.nextship.net/`}>For CrewManager</a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
