import * as Yup from 'yup'

import { getDateByUserFormat, getMaxAdultDate, getMinDate, today } from '../../helpers/timeHelper'

const onlyPositiveDigits = /^\+?[1-9]\d*$/

export const initialValues = {
  first_name: '',
  last_name: '',
  middle_name: '',
  salary: '',
  available_from: null,
  date_of_birth: null,
  gender: null,
  country_of_birth: { name: '', id: null, iso2: null },
  nationality: { name: '', id: null },
  marital_status: { name: '', id: null },
  rank: { name: '', id: null },
  rank_secondary: { name: '', id: null },
  salary_currency: { name: '', id: null },
  salary_rate: { name: '', id: null },
  availability_status: { name: '', id: null },
  english_level: { name: '', id: null },
  city_of_birth: { name: '', id: null },
  user_city_now: { name: '', id: null },
}

export const validationSchema = Yup.object().shape({
  first_name: Yup.string().trim().required('Field is required'),
  last_name: Yup.string().trim().required('Field is required'),

  rank: Yup.object().shape({
    name: Yup.string().required('Need to add at least 1 rank'),
    id: Yup.number().required().positive().integer(),
  }),

  date_of_birth: Yup.date()
    .nullable()
    .max(getMaxAdultDate, 'Your age must be 18 years or older.')
    .min(getMinDate, 'You cannot be older than 100 years.'),
  city_of_birth: Yup.object().shape({
    id: Yup.mixed().test('Check country', 'Select city from list', function (value) {
      const { name } = this.parent

      return name ? Boolean(value) : true
    }),
  }),

  available_from: Yup.date().when('availability_status.id', (availability_status, field) => {
    if (field && availability_status && Number(availability_status) !== 1) {
      return Yup.date()
        .min(today, `The field is available no earlier than ${getDateByUserFormat(today)}`)
        .required('Is a required field')
        .typeError('Invalid date')
        .nullable()
    } else {
      return Yup.date().nullable()
    }
  }),

  availability_status: Yup.object().shape({
    id: Yup.number().required('Is a required field'),
  }),
  salary: Yup.string()
    .matches(onlyPositiveDigits)
    .test(function (value) {
      const { salary_currency, salary_rate } = this.parent
      if (salary_currency.id || salary_rate.id) {
        return !!value
      } else return true
    }),
  salary_currency: Yup.string().when('salary', (field) => {
    if (field) {
      return Yup.object().shape({
        name: Yup.string().required('Field is required!'),
        id: Yup.number().required().positive().integer(),
      })
    } else return Yup.object()
  }),
  salary_rate: Yup.string().when('salary', (field) => {
    if (field) {
      return Yup.object().shape({
        name: Yup.string().required('Field is required!'),
        id: Yup.number().required().positive().integer(),
      })
    } else return Yup.object()
  }),
  user_city_now: Yup.object().shape({
    id: Yup.mixed().test('Check city', 'Select city from list', function (value) {
      const { name } = this.parent

      return name ? Boolean(value) : true
    }),
  }),
})

export const description =
  'Due to the ongoing war in Ukraine, clarify your current location for quick communication with CrewManager. This' +
  ' information can only be seen by CrewManager.'
