import { trackVacancyShare } from '../utils/gtm'

const highlightText = (element) => {
  if (document.body.createTextRange) {
    const range = document.body.createTextRange()
    range.moveToElementText(element)
    range.select()
  } else if (window.getSelection) {
    const selection = window.getSelection()
    const range = document.createRange()

    if (element.childNodes.length > 0) {
      range.setStart(element.childNodes[0], 0)
      range.setEnd(
        element.childNodes[element.childNodes.length - 1],
        element.childNodes[element.childNodes.length - 1].textContent.length
      )
    } else {
      range.selectNodeContents(element)
    }

    selection.removeAllRanges()
    selection.addRange(range)
  } else {
    alert('Highlighting text is not supported in this browser.')
  }
}

export const copyText = async (textToCopyRef) => {
  try {
    const textToCopy = textToCopyRef.current
    highlightText(textToCopy)
    trackVacancyShare(textToCopy?.innerText)
    await navigator.clipboard.writeText(textToCopy.innerText)
  } catch (err) {
    console.error('Error:', err)
  }
}
