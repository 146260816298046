import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProfileFetch } from '../redux/actions/user.action'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import Main from './Main'
import ForgotPassPage from './ForgotPassPage'
import TermsPage from './TermsPage'
import PrivacyPolicyPage from './PrivacyPolicyPage'
import SignupVerifyPage from './SignupVerifyPage'
import ChangePassword from './ChangePassword/ChangePassword'
import SeafarerAuthPage from './SeafarerAuthPage'
import FacebookSignin from './FacebookSignin'
import GoogleSignin from './GoogleSignin'
import Profile from './Profile'
import MobStoreRedirect from './MobStoreRedirect'
import useAuthObserver from '../hooks/useAuthObserver'
import AppleSignin from './AppleSignin'
import DeleteAccountEmail from './DeleteAccauntEmail/DeleteAccountEmail'
import NotFoundPage from './NotFoundPage'
import VideoAdPage from './VideoAdPage'
import Unsubscribe from './Unsubscribe'
import { getHealth } from '../api/getApi'
import TechnicalWorks from '../components/TechnicalWorks/TechnicalWorks'
import usePageGoogleAnalytics from '../hooks/usePageGoogleAnalytics'
import cn from 'classnames'
import useOnline from '../hooks/useOnline'
import Offline from '../components/Offline/Offline'
import { userLogout } from '../redux/actions/auth'
import { useTestWebP } from '../hooks/useTestWebP'
import SeafarerNotPermission from './SettingsPage/SeafarerNotPermission'
import { profilePaths } from '../constants/constans'
import useFCMToken from '../hooks/useFCMToken'
import TagManager from 'react-gtm-module'
import { trackPageView } from '../utils/gtm'

export default function Root() {
  const [healthcheck, setHealthcheck] = useState({
    status: null,
    message: '',
    downtime: '',
  })
  useTestWebP()

  const { isAuth, admin } = useSelector(({ authentication }) => authentication)
  const { scope } = useSelector(({ authentication }) => authentication.user)
  const completionStatusColor = useSelector(({ user }) => user.profile.filler?.color)

  const dispatch = useDispatch()

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const callbackUrl = params.get('callback_url')

  useAuthObserver()
  useFCMToken()
  usePageGoogleAnalytics()

  const isOnline = useOnline()

  useEffect(() => {
    if (window.location.hostname === 'nextship.net') {
      TagManager.initialize({
        gtmId: 'GTM-KFVDCC6',
        dataLayerName: 'dataLayer'
      })
    }
  }, [])

  useEffect(() => {
    trackPageView(location.pathname, document.title)
  }, [location.pathname])

  useEffect(() => {
    const getHealthCheck = async () => {
      setHealthcheck({ ...healthcheck, status: true })

      try {
        const response = await getHealth()

        setHealthcheck({
          ...healthcheck,
          status: true,
          message: response.message,
          downtime: '10 min',
        })
      } catch (error) {
        setHealthcheck({
          ...healthcheck,
          status: false,
          message: error?.response?.data?.message || 'Technical works',
          downtime: error?.response?.data?.downtime || '10 min',
        })
      }
    }

    if (isOnline) getHealthCheck()

    // eslint-disable-next-line
  }, [isOnline])

  useEffect(() => {
    if (isAuth) dispatch(getProfileFetch(scope))

    if (!isAuth) userLogout()

    // eslint-disable-next-line
  }, [isAuth, scope])

  if (!isOnline) return <Offline />

  if (isAuth && !completionStatusColor) return null

  const redirectUrl = completionStatusColor === 'var(--color-accent-light)' ? '/' : profilePaths.personal_data

  const reload = () => window.location.reload()

  return (
    <div className={cn({ adminActivePage: admin?.isAdmin })}>
      {healthcheck.status === null ? null : healthcheck.status ? (
        <Switch>
          <Route exact path='/' component={Main} />
          <Route exact path='/vacancy' component={Main} />
          <Route exact path='/password-reset' component={ForgotPassPage} />
          <Route exact path='/promo' component={VideoAdPage} />
          <Route exact path='/terms' component={TermsPage} />
          <Route exact path='/privacy' component={PrivacyPolicyPage} />
          <Route exact path='/signup-verify' component={SignupVerifyPage} />
          <Route exact path='/seafarer-permission' component={SeafarerNotPermission} />
          <Route exact path='/change-password' component={ChangePassword} />
          <Route exact path='/delete-account-email' component={DeleteAccountEmail} />
          <Route exact path='/seafarer-unsubscribe' component={Unsubscribe} />

          <Route path='/apple-app-site-association' onEnter={reload} />

          <Route path='/auth'>
            {isAuth ? <Redirect to={!callbackUrl ? redirectUrl : callbackUrl} /> : <SeafarerAuthPage />}
          </Route>
          <Route exact path='/seafarer/signin/facebook'>
            {isAuth ? <Redirect to={redirectUrl} /> : <FacebookSignin />}
          </Route>
          <Route exact path='/seafarer/signin/google'>
            {isAuth ? <Redirect to={redirectUrl} /> : <GoogleSignin />}
          </Route>
          <Route exact path='/seafarer/signin/apple'>
            {isAuth ? <Redirect to={redirectUrl} /> : <AppleSignin />}
          </Route>

          <Route exact path='/mobile-store-redirect' component={MobStoreRedirect} />
          <Route path='/profile' component={Profile} />
          <Route path='*' component={NotFoundPage} />
        </Switch>
      ) : (
        <TechnicalWorks data={healthcheck} />
      )}
    </div>
  )
}
