import React from 'react'
import { useFormik } from 'formik'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import './auth-form.scss'
import Separator from '../../UI/Separator/Separator'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import InputPassword from '../../UI/Input/InputPassword'
import { getUserToken } from '../../redux/actions/auth'
import FacebookAuthButton from '../../UI/AuthButtons/FacebookAuthButton'
import GoogleAuthButton from '../../UI/AuthButtons/GoogleAuthButton'
import AppleAuthButton from '../../UI/AuthButtons/AppleAuthButton'
import { setLoginEmail } from '../../redux/actions/user.action'
import { initialValuesSignIn, signInSchema } from './sign.schemas'
import { singInSubmitHelper } from './sign.helper'
import { getCurrentDomain } from '../../helpers/apiHelper'
import MetaTags from '../MetaTags/MetaTags'

document.addEventListener('AppleIDSignInOnSuccess', (data) => {
  console.log(data)
})

const SeamanSignInForm = () => {
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: initialValuesSignIn,
    validationSchema: signInSchema,
    validateOnChange: true,

    onSubmit: (values) => {
      dispatch(getUserToken(singInSubmitHelper(values), setIsButtonDisabled))
    },
  })

  const handleChangeFormikValue = (event) => {
    if (!formik.touched[event.target.name]) {
      formik.setFieldTouched(event.target.name, true)
    }

    if (event.target.name === 'email') {
      dispatch(setLoginEmail(event.target.value))
    }

    formik.handleChange(event)
  }

  const { values, errors, touched } = formik

  return (
    <>
      <MetaTags
        title='NextShip webservice for seafarers log in'
        keywords={
          'create account, create account NextShip, create account Next Ship, NextShip create account,' +
          ' NextShip account, NextShip personal account, create personal account NextShip, Create profile NextShip,' +
          ' create personal profile NextShip, Profile NextShip, Create CV, Fill in CV, Fill out CV, Create CV' +
          ' NextShip, Log In NextShip, NextShip Log in, Create resume seafarer, Create resume NextShip, how to fill' +
          ' out cv, fill out resume, fill out a cv, fill out a resume, attach resume/cv, fill out resume form, cv' +
          ' fill, application form NextShip, fill in application form, fill out application form, create application' +
          ' form NextShip, login NextShip'
        }
      />
      <div className='auth-form auth-form-signin'>
        <div className='auth-form-container'>
          <div className='auth-form__title'>Log In as</div>
          <div className='auth-form__scope_selection'>
            <a className='scope_selection_link' href={`${getCurrentDomain()}employers.nextship.net/`}>
              CrewManager
            </a>
            <span className='scope_selection_active'>Seafarer</span>
          </div>

          <div className='auth-form__inform'>How do you want to Log in?</div>

          <div className='auth-form__social-wrapper'>
            <AppleAuthButton />
            <GoogleAuthButton />
            <FacebookAuthButton />
          </div>

          <Separator text='or' />

          <form className='auth-form__signin_form' onSubmit={formik.handleSubmit}>
            <fieldset className='auth-form__fieldset'>
              <div className='form-group'>
                <Input
                  className='auth-form__input'
                  name='email'
                  isInvalid={!!(errors.email && touched.email)}
                  placeholder='Email.example@domainname'
                  value={values.email}
                  onChange={handleChangeFormikValue}
                />
                {errors.email && touched.email ? (
                  <div className='input-error-message-static'>{errors.email}</div>
                ) : null}
              </div>
              <div className='form-group'>
                <InputPassword
                  theme='password'
                  className='auth-form__input'
                  name='password'
                  isInvalid={!!(errors.password && touched.password)}
                  placeholder='Password'
                  value={values.password}
                  onChange={handleChangeFormikValue}
                />
                {errors.password && touched.password ? (
                  <div className='input-error-message-static'>{errors.password}</div>
                ) : null}
              </div>
              <div className='auth-form__agreement'>
                <div style={{ textAlign: 'right' }}>
                  <Link to='/password-reset'>Forgot password?</Link>
                </div>
              </div>
            </fieldset>
            <Button theme='dark' size='large-full' type='submit' disabled={isButtonDisabled}>
              Log in
            </Button>
          </form>
        </div>
        <div className='auth-form__footer'>
          Don't have account?{' '}
          <b>
            <Link to='/auth/signup'>Create It?</Link>
          </b>
        </div>
      </div>
    </>
  )
}

export default SeamanSignInForm
