import React from 'react'
import styles from './PrivacyTerms.module.scss'
import { Link } from 'react-router-dom'

const PrivacyTerms = () => {
  return (
    <span className={styles.privacyTerms}>
      <Link className={styles.privacy} to={'/privacy'}>
        Privacy policy
      </Link>
      |
      <Link className={styles.terms} to={'/terms'}>
        Terms and conditions
      </Link>
    </span>
  )
}

export default PrivacyTerms
